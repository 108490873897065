<template>
  <div class="merchantsradar clearfix">
    <heand :tagnum="tagnum" />
    <div class="top">
      <span>产业智能评估</span>
      <small @click="$router.go('-1')">返回</small>
    </div>
    <div class="content clearfix">
      <navleft v-on:cateid="getcateid" :tagnumx="tagnumx" />
      <div class="core" v-show="false">
        <div class="Selectprovince">
          <Cascader
            :data="options"
            v-model="area"
            change-on-select
            @on-change="optionschange($event, 5)"
          ></Cascader>
        </div>
        <div class="progress">
          <div class="progress-box" title="反映产业链的完整性，具体指标为产业链各环节企业数量和平均存活时长。">
            <div id="pro0"></div>
            <div class="pro-title">产业链发育度</div>
          </div>
          <div class="progress-box progress-cen" title="产业链发育度、贡献度、创新力、成长性和资本力的加权平均">
            <div class="pro-num">
              <span>{{ general }}</span>分
            </div>
            <div class="pro-title">综合健康评分</div>
          </div>
          <div class="progress-box" title="反映产业链，具体为新增注册企业和注册资本的增速。">
            <div id="pro1"></div>
            <div class="pro-title">产业链成长性</div>
          </div>
          <div class="progress-box" title="反映产业链对区域经济拉动作用，具体指标为就业贡献。">
            <div id="pro2"></div>
            <div class="pro-title">产业链贡献力</div>
          </div>
          <div class="progress-box" title="反映产业链的创新能力，具体为知识产权和高新技术企业数量占比。">
            <div id="pro3"></div>
            <div class="pro-title">产业链创新力</div>
          </div>
          <div class="progress-box" title="反映产业链的资金状况，具体为上市企业和融资企业占比">
            <div id="pro4"></div>
            <div class="pro-title">产业链资本力</div>
          </div>
        </div>
      </div>
      <div
        class="navright"
        style="width: calc(100% - 256px - 20px) !important;height:auto !important;min-height:auto !important; box-sizing:border-box;margin-left:20px;"
      >
        <div class="navright-top">
          <div class="nav-box" @click="clicknav(3)" :class="navbox == 3 ? 'active' : ''">
            <div>
              <p>优势环节</p>
              <p>{{ listsize.goodness }}</p>
            </div>
          </div>
          <div class="nav-box" @click="clicknav(2)" :class="navbox == 2 ? 'active' : ''">
            <div>
              <p>薄弱环节</p>
              <p>{{ listsize.bedness }}</p>
            </div>
          </div>
          <div class="nav-box" @click="clicknav(1)" :class="navbox == 1 ? 'active' : ''">
            <div>
              <p>缺失环节</p>
              <p>{{ listsize.deletion }}</p>
            </div>
          </div>
        </div>
        <h2 style="text-align:left;font-size: 18px;font-weight: bold;color: #333333;">强链补链列表</h2>
        <div class="navright-bottom" style="width:100% !important;flex-wrap:wrap; display:flex;">
          <p
            v-for="(item, index) in listdata"
            :key="index"
            @click="jump(index, item)"
            :class="num == index ? 'active' : ''"
            style="text-align:center;display:flex;justify-content:center;"
          >
            <span>{{ item }}</span>
            <!-- <img src="@/assets/merchantsradar/jiantou.png" alt /> -->
          </p>
        </div>
        <div class="pageing listpage" v-if="navbox == 1">
          <el-pagination
            @current-change="handleCurrentChangelist"
            :page-size="10"
            layout="total,prev, pager, next"
            :current-page.sync="currentPage3"
            :total="Number(listsize.deletion)"
          ></el-pagination>
        </div>
        <div class="pageing listpage" v-if="navbox == 3">
          <el-pagination
            @current-change="handleCurrentChangelist"
            :page-size="10"
            :current-page.sync="currentPage2"
            layout="total,prev, pager, next"
            :total="Number(listsize.goodness)"
          ></el-pagination>
        </div>
        <div class="pageing listpage" v-if="navbox == 2">
          <el-pagination
            @current-change="handleCurrentChangelist"
            :page-size="10"
            :current-page.sync="currentPage1"
            layout="total,prev, pager, next"
            :total="Number(listsize.bedness)"
          ></el-pagination>
        </div>
        <div v-if="merchantsradar2 == 2" class="merchantsradar2" ref="merchantsradar2" style>
          <!-- <h2>产品概述</h2>
          <p>{{ botdata.info }}</p>-->
          <!-- <h2>产品概述</h2>
          <p>{{ botdata.info }}</p>-->
           <div v-if="isloading" class="loading">
              <img src="../../assets/loading.gif" alt />
            </div>
          <div style="display:flex;justify-content:space-between;align-items:center;">
            <!-- 加载效果 -->
           
            <h2>招商企业列表</h2>
            <!-- <div>
        区域类型 : 
        <el-select class="iipp" style="width:160px;margin:0 10px;" v-model="select" placeholder="请选择" @change="labelchange($event)">
          <el-option
            v-for="(item,index) in optionslevel"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
            </div>-->
          </div>

          <div class="table">
            <ul class="thead">
              <li>企业名称</li>
              <li>注册资本</li>
              <li>成立时间</li>
              <li>经营状态</li>
              <li>企业热度</li>
            </ul>
            <ul v-for="(item, index) in botdata.df" @click="goDetails(item.id)" :key="index">
              <li>{{ item.company_name }}</li>
              <li>{{ item.capital }}</li>
              <li>{{ item.buildate }}</li>
              <li>{{ item.status }}</li>
              <li>
                <img v-for="item in item.hots" src="../../assets/merchantsradar/zuihuo.png" alt />
                <img
                  v-for="item in 5 - item.hots"
                  src="../../assets/merchantsradar/zuihuo2.png"
                  alt
                />
                <!-- {{ item.hots }}星 -->
              </li>
            </ul>
          </div>
          <div class="pageing botpageing">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage4"
              layout="total, prev, pager, next"
              :page-size="6"
              :total="Number(botdata.size)"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  evaluation,
  radarprolink,
  radarrecommond,
  selquery,
  getRegion,
} from "@/api/index";
export default {
  name: "merchantsradar",
  data() {
    return {
      isloading: false,
      optionslevel: [],
      select: "其他",
      hotimg: [],
      hotimg2: [],
      merchantsradar2: 1, //雷达二页
      tagnum: 2,
      tagnumx: 2,
      value: "",
      navbox: 3,
      currentPage1: 1,
      currentPage2: 1,
      currentPage3: 1,
      currentPage4: 1,
      inid: "", // 二级行业id（ 1 级行业不做选项， 只做展开）
      area: ["山西省", "太原市", ""], //省市区【 省， 市， 区】
      product: "", // 产品
      platedata: [],
      general: "",
      page: 1,
      perpage: 9,
      listdata: [],
      listsize: {
        deletion: 0,
      },
      num: -1,
      botpage: 1,
      botperpage: 6,
      botsize: "",
      botdata: {
        size: 0,
      },
      listname: "",
      options: [],
    };
  },
  created() {
    this.inid = localStorage.getItem("inidchild");
    this.querydata();
    this.getdata();
    this.getRegion();
  },
  mounted() {},
  destroyed() {},
  methods: {
    labelchange() {
      console.log("改变了");
      this.botpage = 1;
      this.currentPage4 = 1;
      this.getradarrecommond();
    },
    querydata() {
      selquery().then((res) => {
        console.log(res);
        this.optionslevel = res;
        // this.select = res[0].value
        this.select = "其他";
      });
    },
    goDetails(id) {
      //       let href = this.$router.resolve({
      //         path: "/industrial/chdetsils9",
      //         query: {
      //           id: id,
      //           tagnum: 3,
      //         },
      //       });
      //       window.open(href.href, "_blank");
      this.$router.push({
        path: "/industrial/chdetsils9",
        query: {
          id: id,
          tagnum: 3,
        },
      });
    },

    getcateid(data) {
      this.cate = data.cate;
      this.inid = data.id;
      this.num = -1;
      if (this.cate != 1) {
        this.getdata();
      } else {
      }
      if (this.inid != 8) {
        this.tagnumx = -1;
      }
    },
    // 获取数据
    getdata() {
      this.getevaluation();
      this.getradarprolink();
    },
    async getevaluation() {
      this.platedata = [];
      const res = await evaluation({
        inid: this.inid, // 二级行业id（ 1 级行业不做选项， 只做展开）
        area: this.area, //省市区【 省， 市， 区】
      });
      this.platedata.push(res.development); //发展
      this.general = res.general; // 健康
      this.platedata.push(res.growup); // 成长
      this.platedata.push(res.contribute); //贡献
      this.platedata.push(res.innovate); //创新
      this.platedata.push(res.capital); // 资本
      this.ceshi();
    },
    async getradarprolink() {
      const res = await radarprolink({
        inid: this.inid, // 二级行业id（1级行业不做选项，只做展开）
        area: this.area, // 省市区【省，市，区】
        link_type: this.navbox, //   # 缺失环节1，薄弱环节2，优势环节3
        page: this.page, //
        perpage: this.perpage,
      });
      this.listdata = res.df;
      this.listsize = res.size;
    },
    async getradarrecommond() {
      this.isloading = true;
      const res = await radarrecommond({
        name: this.listname, //  # 产品名
        page: this.botpage,
        perpage: this.botperpage,
        select: this.select,
      });
      this.isloading = false;
      this.botdata = res;
      // console.log(res,"3333333333333")
    },
    jump(num, name) {
      this.merchantsradar2 = 2;
      this.num = num;
      this.listname = name;
      this.getradarrecommond();
      setTimeout(function () {
        window.scrollTo({
          top: 800,
          behavior: "smooth",
        });
      }, 100);
    },
    // 获取省市区
    async getRegion() {
      const res = await getRegion();

      this.options = res;
    },
    // 下拉框匹配数据
    optionschange(e, data, num) {
      if (e[0] != undefined) {
        this.optionsvalue2 = e[0] + "/" + e[1] + "/" + e[2];
      } else {
        this.optionsvalue2 = "";
      }
      if (e.length == 1) {
        e.push("");
        e.push("");
      } else if (e.length == 2) {
        e.push("");
      }
      this.area = e;
      this.getdata();
    },
    handleNodeClick(data) {},
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleSizeChangelist(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.botpage = val;
      this.getradarrecommond();
    },
    handleCurrentChangelist(val) {
      this.page = val;
      this.getradarprolink();
    },
    clicknav(data) {
      this.navbox = data;
      this.page = 1;
      this.num = -1;
      this.getradarprolink();
    },
    ceshi() {
      var arr = this.platedata;
      for (var i = 0; i < 5; i++) {
        this.picture(arr[i], i);
      }
    },

    picture(data, i) {
      const getid = "pro" + i;
      let myChart = this.$echarts.init(document.getElementById(getid));
      var dataArr = data;
      var colorSet = {
        color: "#CE4F78",
      };
      var text;
      let option = {
        tooltip: {
          show: true,
          trigger: "item",
          position: "top",
          // formatter: function(e) {
          //     return '良';
          // },
          // color: '#CCC'
        },
        series: [
          {
            name: "数值进度条",
            type: "gauge",
            radius: "50%",
            splitNumber: 0,
            data: [dataArr],
            axisLine: {
              lineStyle: {
                color: [
                  [dataArr / 100, "#F1DC9A"],
                  [1, "red"],
                ],
                width: 15,
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              length: 0,
              lineStyle: {
                color: "#fff",
                width: 15,
              },
            },

            pointer: {
              show: true,

              length: "70%", // 指针长度，可以是绝对数值，也可以是相对于半径的百分比,默认 80%。
              width: 2,
            },
            detail: {
              show: true,
              color: "#fff",
              fontSize: 20,
              offsetCenter: [0, "60%"],
              formatter: "{value}分",
            },
          },
          {
            name: "背景进度条",
            type: "gauge",
            // center: ['20%', '50%'],
            radius: "50%",
            // data: [0],
            splitNumber: 20,
            axisLine: {
              lineStyle: {
                color: [
                  [dataArr / 100, colorSet.color],
                  [1, "#163CD4"],
                ],
                width: 15,
              },
            },
            axisLabel: {
              show: false,
              color: "#163CD4",
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              length: 15,
              lineStyle: {
                color: "#092FBE",
                width: 5,
              },
            },

            pointer: {
              show: false,
            },
            detail: {
              show: false,
              // color:'#fff'
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.merchantsradar {
  background-color: #f2f4f7;
  padding-bottom: 30px;
  .top {
    background-color: #fff;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 500;
      color: #222222;
      margin-left: 30px;
    }
    small {
      display: inline-block;
      line-height: 31px;
      text-align: center;
      width: 88px;
      height: 31px;
      background: #fe6a00;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      margin-right: 40px;
      cursor: pointer;
    }
  }
  .content {
    padding: 10px 30px;
    min-width: 1280px;
    box-sizing: border-box;

    .core {
      margin: 0 10px;
      float: left;
      width: calc(100% - 256px - 452px - 10px);
      padding: 0 15px 15px 15px;
      min-height: 666px;
      background-color: #fff;
      text-align: left;
      .Selectprovince {
        padding: 14px 0 10px 18px;
      }
      .el-select {
        width: 164px;
        height: 30px;
        input {
          width: 164px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
      }
      .progress {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        background: url("../../assets/merchantsradar/pro.png") no-repeat;
        background-size: 100% 100%;
        min-height: 700px;
        padding: 0 50px;
        padding-top: 90px;
        padding-bottom: 40px;
        .progress-box {
          width: 280px;
          height: 280px;
          background: url("../../assets/merchantsradar/time.png") no-repeat;
          background-size: 100% 100%;
          position: relative;
          .demo1-bg1 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .pro-title {
            width: 165px;
            height: 43px;
            line-height: 43px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #93e9ff;
            background: linear-gradient(-18deg, #286ff0, #1755ff);
            border-radius: 22px;
            position: absolute;
            bottom: -21px;
            left: 50%;
            transform: translateX(-50%);
          }
          .pro-num {
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translateX(-50%);
            width: 80px;
            text-align: center;
            height: 30px;
            line-height: 30px;
          }
        }
        .progress-cen {
          width: 379px;
          height: 325px;
          background: url("../../assets/merchantsradar/dashuju.png") no-repeat;
          transform: translateY(-50px);
          .pro-num {
            display: table-cell;
            vertical-align: bottom;
            top: 48%;
            vertical-align: bottom;
            span {
              font-size: 42px;
              font-weight: bold;
              top: 44%;
              line-height: 42px;
            }
          }
        }
      }
    }
    .navright {
      float: left;
      width: 442px;
      min-height: 615px;
      background: #ffffff;
      padding: 18px 14px 14px 14px;
      position: relative;
      .navright-top {
        display: flex;
        width: 100%;
        justify-content: left;
        align-items: center;
        margin-bottom: 50px;
        .nav-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32%;
          background: #f2f4f7;
          border-radius: 10px;
          height: 85px;
          width: 140px;
          margin-right: 30px;
          cursor: pointer;
          p {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            &:nth-child(2) {
              margin-top: 5px;
            }
          }

          &.active {
            background: #fe6a00;
            p {
              color: #fff;
            }
          }
        }
      }
      .navright-bottom {
        padding-top: 15px;
        cursor: pointer;
        h2 {
          height: 40px;
          line-height: 40px;
          text-align: left;
          border-bottom: 1px solid #eee;
          font-size: 15px;
          font-weight: 400;
          color: #333333;
        }
        p {
          height: 40px;
          width: 33.3%;
          line-height: 40px;
          text-align: left;
          border-bottom: 1px solid #eee;
          font-size: 14px;
          padding-left: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #f3f6fb;
          &:nth-child(4) {
            background-color: #fff;
          }
          &:nth-child(5) {
            background-color: #fff;
          }
          &:nth-child(6) {
            background-color: #fff;
          }
          img {
            width: 8px;
            height: 15px;
            margin-right: 13px;
          }
          &.active {
            color: #fe6a00;
          }
        }
      }
      .listpage {
        // position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        // bottom: 10px;
      }
    }
  }
  .merchantsradar2 {
    // width: calc(100% - 256px - 68px);
    // margin-right: 30px;
    // float: right;
    // min-height: 561px;
    min-height: 457px;
    background: #ffffff;
    text-align: left;
    // padding-top: 32px;
    // padding-left: 30px;
    // padding-right: 30px;
    position: relative;
    h2 {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    p {
      margin-top: 10px;
      line-height: 26px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 40px;
    }
    .table {
      font-size: 14px;
      font-weight: 400;
      color: #222222;
      margin-top: 26px;
      // width: 100%;
      li {
        float: left;
        list-style: none;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #222222;
        height: 40px;
        line-height: 40px;
        text-align: left;
        &:nth-child(1) {
          width: 28%;
          padding-left: 5%;
          text-align: left;
        }
        &:nth-child(2) {
          width: 15%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 25%;
          img {
            margin-right: 7px;
          }
        }
      }
      ul:first-child {
        cursor: default;
      }
      ul {
        background: #f3f6fb;
        height: 40px;
        cursor: pointer;
      }
      ul:nth-child(2n) {
        background: #ffffff;
      }
    }
    .pageing {
      text-align: center;
      margin-top: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 14px;
      }
    }
    .botpageing {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 20px;
    }
  }
}
.clock {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 20%;
  left: -22%;
  z-index: 99;
  border-radius: 50%;
}

.clock div:nth-child(1),
.clock div:nth-child(2) {
  position: absolute;
  -webkit-transform-origin: bottom;
}

.clock div:nth-child(1) {
  top: 45px;
  left: 197px;
  width: 8px;
  height: 41px;
  border-top-right-radius: 50px;
  background: url("../../assets/merchantsradar/zhen.png") no-repeat;
  transform: rotate(0deg);
}
#pro0,
#pro1,
#pro2,
#pro3,
#pro4 {
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.merchantsradar .content .core .Selectprovince {
  width: 200px;
}
.el-pagination {
  padding: 40px 5px;
}
.loading > img {
  // width:100vw;
  // height:100vh;
  position:absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 50px;
  width: 50px;
}
</style>